<template>
<div class="mobile-page-layout">

    <div class="mobile-page-header">
        <div class="mobile-page-header-row">
            <div class="mobile-page-header-right">
                
                <router-link :to="{ name: 'NewTechnical'}" class="mobile-page-header-icon pointer back-icon">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </router-link>


                <div class="mobile-page-header-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="header-svg-icon">
                        <path d="M160 320c53.02 0 96-42.98 96-96c0-53.02-42.98-96-96-96C106.1 128 64 170.1 64 224C64 277 106.1 320 160 320zM192 352H128c-70.69 0-128 57.3-128 128c0 17.67 14.33 32 32 32h256c17.67 0 32-14.33 32-32C320 409.3 262.7 352 192 352z" class="fa-primary"></path>
                        <path d="M592 0h-384C181.5 0 160 22.25 160 49.63V96c23.42 0 45.1 6.781 64 17.8V64h352v288H287.3c22.07 16.48 39.54 38.5 50.76 64h253.9C618.5 416 640 393.8 640 366.4V49.63C640 22.25 618.5 0 592 0zM312 218l55.01 54.99c9.375 9.375 24.6 9.375 33.98 0L473 201l24.25 24.25C508.6 236.6 528 228.6 528 212.5V124c0-6.625-5.375-12-12-12h-88.5c-16.12 0-24.12 19.38-12.75 30.75L439 167L384 222l-55.01-55.01c-9.375-9.375-24.6-9.375-33.98 0L280.3 181.8C285 195.1 288 209.1 288 224c0 6.875-.9999 13.38-2 20L312 218z" class="fa-secondary"></path>
                    </svg>
                </div>
                <div class="mobile-page-header-text">
                    مشاهده تحلیل
                </div>  
            </div>
            <div class="mobile-page-header-actions">

                <div class="mobile-page-header-icon pointer" style="position: relative;">
                    <i class="header-uil-icon uil uil-thumbs-up" style="color:#e63946;font-size: 28px;" v-if="technical.liked"  @click="likePost(technical.technical.id)"></i>
                    <i class="header-uil-icon uil uil-thumbs-up" v-else style="font-size: 28px;"  @click="likePost(technical.technical.id)"></i>
                    <span style="position: absolute;top: 3px;left: 38px;font-size: 19px;direction: ltr;font-weight: 600;">{{ technical.likes }}</span>
                </div>      
                
                <div class="mobile-page-header-menu">
                    <template>
                        <div class="text-center">
                            <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="mobile-page-header-icon pointerv margin-0" v-bind="attrs" v-on="on">
                                    <i class="header-material-icon mdi mdi-dots-vertical"></i>
                                </div>
                            </template>
                            <v-list>
                                <v-list-item v-for="(item, index) in menus" :key="index"  @click.prevent="get_modal(item.modal)">
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                            </v-menu>
                        </div>
                    </template>                 
                </div>
            </div>
        </div>
    </div>
    <div class="mobile-page-info">
        <div class="mobile-page-info-row">
            <div class="mobile-page-info-col">
                <slot v-if="$store.state.user && technical.user.username != $store.state.user.username">
                    <router-link v-if="!widgetLoad" :to="{ name: 'ProfileOthers' , params: {username : technical.user.username} }" class="left tgju-btn-badge tgju-btn-light">
                        <i class="uil uil-user"></i>
                        {{ technical.user.firstname }} {{ technical.user.lastname }}
                    </router-link>
                </slot>
                <slot v-else>
                    <div v-if="!widgetLoad" class="m-left tgju-btn-badge tgju-btn-light">
                        <i class="uil uil-user"></i>
                        {{ technical.user.firstname }} {{ technical.user.lastname }}
                    </div>
                </slot>
            </div>
            <div class="mobile-page-info-col">
                <div v-if="!widgetLoad" class="m-left tgju-btn-badge tgju-btn-light">
                    <i class="uil uil-clock-nine"></i>
                    <span> {{ toRelative(technical.created_at) }}</span>
                </div>
            </div>
        </div>
        <p>
            مشاهده تحلیل شماره {{ this.$route.params.id }} با عنوان {{ technical.title}} 
        </p>

    </div>

    <slot v-if="widgetLoad">
        <div class="tgju-widget-content full-padding">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </div>
    </slot>
    <slot v-else>
        <div class="technical-chart">
            <ViewTechnicalComponent :data="data"></ViewTechnicalComponent>
        </div>
    </slot>



    <div class="mobile-page-info mobile-page-info-content">
        <p>
            <slot v-if="$helpers.isJson(technical.attachments) && technical.attachments && $helpers.json_pars(technical.attachments)[1]">
                <a :href="$helpers.api_url() + $helpers.json_pars(technical.attachments)[1].name" target="_blank">
                    <img :src="$helpers.api_url() + $helpers.json_pars(technical.attachments)[1].name" style="width:100%;height:auto;margin-bottom:20px;max-width:350px">
                </a>
            </slot>
            {{ technical.content }}
        </p>
    </div>

    <div class="row tgju-widgets-row technical-m-top profile-mobile-view mobile-page-layout">
        <div class="col-12 col-md-12 col-lg-12 col-xl-6" style="margin: 0 !important;padding: 0 !important;">
            <Post type="widget" auth="hide" username="everybody" sendbox="enabled" :subject="subject"></Post>
        </div>
    </div>
</div>
</template>

<script>
// این ویو برای مشاهده جزئیات یک تحلیل در پنل کاربری مورد استفاده قرار میگیرد 
import ViewTechnicalComponent from '@/components/Technical/ViewTechnical.vue';
import Post from '@/components/Mobile/TgjuPosts/Post.vue';
import moment from "moment-jalaali";

export default {
    name: 'ViewTechnical',
    components: {
        ViewTechnicalComponent,
        Post,
    },
    data: function () {
        return {
            data: {},
            technical: {
                title: undefined,
                user: {
                    firstname: undefined,
                    lastname: undefined,
                    username: undefined,
                }
            },
            subject: 'dashboard-technical-' + this.$route.params.id,
            widgetLoad: true,
            menus: [
                { title: 'تابلو تحلیل‌ها', modal: 'technicals' },
                { title: 'تحلیل‌گرها', modal: 'analysts' },
                { title: 'راهنما', modal: 'help' }
            ],
        }
    },
    mounted() {
        // در صورتی که کارب لاگین کرده بود یک سری اکشن ها را کال کن
        if (this.$helpers.userSync()) {
            this.$store.commit('setAccessToken', localStorage.getItem('access_token'));
            this.$store.dispatch('setUserData', { forced: true });
            // this.hit(this.$route.query.tech_id);
        }

        this.data = {
            id: this.$route.params.id,
        }
        this.getTechnical(this.$route.params.id);

        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    methods: {
        // تبدیل تاریخ به مقادیر مناسب
        toRelative(date) {
            return moment(date).fromNow();
        },
        // ارسال درخواست به وبسرویس جهت دریافت اطلاعات تحلیل
        getTechnical(id) {
            this.$helpers.makeRequest('GET', `/technical/find/${id}`).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.data.status == false) {
                    this.$router.push({ name: 'Profile' });
                    return;
                }

                this.technical = api_response.data.response.technical;
                this.widgetLoad = false;
            });
        },
        // ارسال درخواست ای پی آی جهت افزایش بازدید
        hit(id) {
            this.$helpers.makeRequest('POST', `/technical/hit/${id}`).then(api_response => {});
        },
        // ارسال درخواست جهت لایک کردن تحلیل
        likePost(id) {
            let api_data = {
                action: this.technical.liked ? 'unlike' : 'like'
            };

            this.$helpers.makeRequest('GET', '/technical/like/' + id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this.technical.liked = !this.technical.liked;
                    this.technical.likes = api_response.data.response.likes;
                }
            });
        },
        // دکمه بازگشت
        back() {
            this.$router.back()
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Technical.vue',
                title: 'تحلیل‌ها',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
